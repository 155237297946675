import '../App.css';
import logo from '../logo.svg';
import img1 from '../img/Illustration1.png';
import img2 from '../img/Illustration2.png';
import img3 from '../img/Standard.png';
import img4 from '../img/Premium.png';
import { FaCheck, FaRegUser, FaUser } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { auth, googleProvider } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import axios from 'axios';
import { CiLogout } from "react-icons/ci";
import { Link } from 'react-router-dom';

function Home() {
    const [signUp, setSignUp] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const [alert, setAlert] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [userDropdown, setUserDropdown] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const user = useUser();
    
    const handleRetrieveCustomer = async () => {
        setError(null);
    
        try {
          const response = await axios.get('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/retrieve-subscriptions', {
            params: { customerEmail: user.email },
          });
    
          console.log('Customer data:', response.data);
          setCustomerData(response.data);
        } catch (error) {
          setError('Customer retrieval failed');
        }
    };

    useEffect(() => {
        if (user?.email) {
            handleRetrieveCustomer();
        }
    }, [user, loading]);
  
    const registerWithEmail = () => {
        createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
            setSignIn(false);
            setSignUp(false);
        })
            .catch((error) => {
            console.error(error);
        });
    };
  
    const loginWithEmail = () => {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            setSignIn(false);
            setSignUp(false);
        })
        .catch((error) => {
            console.error(error);
        });
    };
  
    const loginWithGoogle = () => {
      signInWithPopup(auth, googleProvider)
        .then(() => {
            setSignIn(false);
            setSignUp(false);
        })
        .catch((error) => {
            console.error(error);
        });
    };
  
    const logout = () => {
        signOut(auth)
        .catch((error) => {
            console.error(error);
        });
    };

    const handleSubscribe = async (planId) => {
        setLoading(true);
        setError(null);

        try {
        const response = await axios.post('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/create-subscription', {
            planId: planId,
            customerEmail: user.email,
        });

        const { url } = response.data;

        window.location.href = url;
        } catch (error) {
        setError('Subscription creation failed');
        } finally {
        setLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        setLoading(true);
        
        try {
            const response = await axios.post('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/cancel-subscription', {
                subscriptionId: customerData?.data[0]?.id,
            });
    
            if (response.status === 200) {
                console.log('Subscription canceled successfully:', response.data);
            } else {
                throw new Error('Failed to cancel subscription');
            }
            setAlert(false)
        } catch (error) {
            const errorMessage = error?.response?.data?.error || error.message;
            console.error('Error canceling subscription:', errorMessage);
        } finally{
            setLoading(false);
        }
    };

    return (
        <div className='conteniner'>
            <header className='header'>
                <Link to={'/'} className='header-logo'>
                    <img src={logo} alt='Smart Logo' />
                    Smart Surf
                </Link>

                {user ? (
                    <div className='profile-block'>

                        {customerData?.data.length > 0 ? 
                            <>
                                <div className='plan-status'>
                                    {customerData.data[0].plan.interval} Plan
                                </div>
                            </>
                            :
                            <div className='plan-status'>
                                Free Plan
                            </div>
                        }
                        <p>
                            {user.email}
                        </p>
                        <div className='profile-logo' onClick={() => setUserDropdown(!userDropdown)}>
                            {user.photoURL ?
                                <img src={user.photoURL} alt='Profile' />
                                :
                                <FaRegUser />
                            }
                        </div>
                        {userDropdown &&
                            <div className='profile-dropdown'>
                                {customerData?.data.length > 0 && 
                                    <>
                                        {customerData?.data[0].cancel_at_period_end ?
                                            <p className='dropdown-btn sub-text'>
                                                You do not have an active subscription
                                            </p>
                                            :
                                            <button className='dropdown-btn red-text' 
                                                onClick={() => {
                                                    if(customerData?.data[0].status === 'trialing') {
                                                        setAlert(true);
                                                        setUserDropdown(!userDropdown)
                                                    } else {
                                                        setUserDropdown(!userDropdown)
                                                        handleCancelSubscription();
                                                    }
                                                }}
                                            >
                                                Cancel Subscription
                                            </button>
                                        }
                                    </>
                                }
                                <button className='dropdown-btn' onClick={logout}>
                                    <CiLogout className='rotate' />
                                    Logout
                                </button>
                            </div>
                        }
                    </div>
                ) : (
                    <div className='reg-block'>
                        <button className='login' onClick={() => {setSignIn(true)}}>Sign In</button>
                        <button className='reg' onClick={() => {setSignUp(true)}}>Sign Up</button>
                    </div>
                )}
            </header>

            {alert ? (
                <div className='modal-bg'>
                    <div className='modal-body'>
                        <div className='modal-header'>
                            <h4>Cancel Free Trial</h4>
                            <IoClose className='close' onClick={() => {setAlert(false)}} />
                        </div>

                        <div className='modal-content'>
                            
                            <p className='modal-text'>
                                When canceling the subscription during the free trial, the plan will be changed to "Free"
                            </p>

                            <div className='modal-btn-block'>
                                <button className='modal-btn' onClick={() => {setAlert(false)}}>
                                    No
                                </button>
                                <button className='modal-btn modal-btn-yes' onClick={handleCancelSubscription}>
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                )
                : null
            }

            {signUp || signIn ? (
                <div className='modal-bg'>
                    <div className='modal-body'>
                    <div className='modal-header'>
                        {signUp ? 
                            <>
                                <h4>Registration</h4>
                                <IoClose className='close' onClick={() => {setSignUp(false)}} />
                            </>
                        : signIn ?
                            <>
                                <h4>Login</h4>
                                <IoClose className='close' onClick={() => {setSignIn(false)}} />
                            </>
                        : null
                        }
                    </div>

                        <div className='modal-content'>
                            <input
                                type="email"
                                placeholder="Email"
                                className='modal-input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                className='modal-input'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className='title-text-link title-text-link-modal'>

                            {signUp ? 
                                <button onClick={registerWithEmail}>
                                    Register
                                </button>
                            : signIn ?
                                <button onClick={loginWithEmail}>
                                    Login
                                </button>
                            : null
                            }
                            </div>
                            <div className='or-line'>
                                <p>or</p>
                            </div>
                            <button className='google-button' onClick={loginWithGoogle}>
                                <FcGoogle />
                                Login with Google
                            </button>

                            {signUp ? 
                                <div className='modal-bottom-link' onClick={() => {setSignIn(true); setSignUp(false)}}>
                                    Already have an account? <span>Log In</span>
                                </div>
                            : signIn ?
                                <div className='modal-bottom-link' onClick={() => {setSignIn(false); setSignUp(true)}}>
                                    Don't have an account? <span>Sign Up</span>
                                </div> 
                            : null
                            }
                        </div>
                    </div>
                </div>
                )
                : null
            }

            <div className='title-block'>
                <div className='title-text-block'>
                    
                    <h3>
                        Want anything to be easy with <span>Smart Surf.</span> 
                    </h3>
                    <p>
                        Process information faster and more conveniently with us. Learn more about our features.
                    </p>
                    
                    <div className='title-text-link'>
                        <Link to='https://chrome.google.com/webstore/detail/smart-surf/ihidhmamafppcffbeonkhhkcapjpbbjh?hl=uk' target="_blank" className='btn'>Get Started</Link>
                    </div>

                </div>

                <div className='title-img-block'>
                    <img src={img1} alt='Illustration1' />
                </div>

                <div className='ststistic-block'>
                    <div className='ststistic-item'>
                        <div className='ststistic-item-circle'>
                            <FaUser />
                        </div>
                        <div className='ststistic-item-text'>
                            <h6>90+</h6>
                            <p>Users</p>
                        </div>
                    </div>

                    <div className='line'></div>

                    <div className='ststistic-item'>
                        <div className='ststistic-item-circle'>
                            <FaUser />
                        </div>
                        <div className='ststistic-item-text'>
                            <h6>v3.5 - v4</h6>
                            <p>ChatGPT</p>
                        </div>
                    </div>

                    <div className='line'></div>

                    <div className='ststistic-item'>
                        <div className='ststistic-item-circle'>
                            <FaUser />
                        </div>
                        <div className='ststistic-item-text'>
                            <h6>30+</h6>
                            <p>Languages</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='content-block'>

                <div className='content-img-block'>
                    <img src={img2} alt='Illustration2' />
                </div>
                
                <div className='content-text-block'>
                    
                    <h4>
                        We Provide Many Features You Can Use
                    </h4>

                    <p>You can explore the features that we provide with fun and have their own functions each feature.</p>

                    <div className='content-text-item'>
                        <IoIosCheckmarkCircle className='content-text-icon' />
                        <p>Analysis of page content</p>
                    </div>

                    <div className='content-text-item'>
                        <IoIosCheckmarkCircle className='content-text-icon' />
                        <p>Short text content with keywords</p>
                    </div>

                    <div className='content-text-item'>
                        <IoIosCheckmarkCircle className='content-text-icon' />
                        <p>Blocking advertising banners</p>
                    </div>

                </div>
            </div>

            <div className='plan-block'>
                <h3>
                    Choose Your Plan
                </h3>
                <p className='plan-block-p'>Let's choose the package that is best for you and explore it happily and cheerfully.</p>

                <div className='plan-content'>
                    <div className='plan-item'>
                        <img src={img3} alt='Plan' />
                        <h5>Month Plan</h5>
                        <div className='plan-text-item'>
                            <FaCheck className='plan-text-icon' />
                            <p>Analysis of page content</p>
                        </div>
                        <div className='plan-text-item'>
                            <FaCheck className='plan-text-icon' />
                            <p>Short text content with keywords</p>
                        </div>
                        <div className='plan-text-item'>
                            <FaCheck className='plan-text-icon' />
                            <p>Blocking advertising banners</p>
                        </div>

                        <div className='plan-name'>
                            <span>$2.5</span> / month
                        </div>

                        <button onClick={() => {handleSubscribe("price_1PTiTmP6fHbfdhprUDMuDZW2")}} disabled={loading} className='plan-btn'>Choose Plan</button>
                    <div>
                    </div>
                    </div>

                    <div className='plan-item plan-item-blue'>
                        <img src={img4} alt='Plan' />
                        <h5>Year Plan</h5>
                        <div className='plan-text-item'>
                            <FaCheck className='plan-text-icon' />
                            <p>Analysis of page content</p>
                        </div>
                        <div className='plan-text-item'>
                            <FaCheck className='plan-text-icon' />
                            <p>Short text content with keywords</p>
                        </div>
                        <div className='plan-text-item'>
                            <FaCheck className='plan-text-icon' />
                            <p>Blocking advertising banners</p>
                        </div>
                        
                        <div className='plan-name'>
                            <span>$25</span> / year
                        </div>

                        <button onClick={() => {handleSubscribe("price_1PTiTmP6fHbfdhpr0LC3XOnR")}} disabled={loading} className='plan-btn plan-btn-blue'>Choose Plan</button>          
                    <div>
                    </div>
                    </div>
                </div>

                <div className='download-block'>
                    <h3>Download our Chrome Extension to use all the features</h3>

                    <div className='title-text-link m-0'>
                        <Link to='https://chrome.google.com/webstore/detail/smart-surf/ihidhmamafppcffbeonkhhkcapjpbbjh?hl=uk' target="_blank" className='btn m-0'>Download Now</Link>
                    </div>
                </div>
            </div>

            <div className='footer-block'>
                <div>
                    <Link to={'/'} className='header-logo'>
                        <img src={logo} alt='Smart Logo' />
                        Smart Surf
                    </Link>
                    <p className='grey-text'>
                        ©2024 SmartSurf
                    </p>
                </div>

                <div className='footer-right'>
                    {/* <div>
                        <h6>Product</h6>
                        <p>Download</p>
                        <p>Pricing</p>
                    </div> */}

                    <div>
                        <h6>Engage</h6>
                        <Link className='footer-link' to="/privacy-policy">Privacy Policy</Link>
                        <Link className='footer-link' to="/terms-of-service">Terms of Service</Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Home;
