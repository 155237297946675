import '../App.css';
import logo from '../logo.svg';
import img1 from '../img/Illustration1.png';
import img2 from '../img/Illustration2.png';
import img3 from '../img/Standard.png';
import img4 from '../img/Premium.png';
import { FaCheck, FaRegUser, FaUser } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { auth, googleProvider } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import axios from 'axios';
import { CiLogout } from "react-icons/ci";
import { Link } from 'react-router-dom';

function TermsOfService() {
    const [signUp, setSignUp] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const [alert, setAlert] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [userDropdown, setUserDropdown] = useState(false);

    const user = useUser();
    
    const handleRetrieveCustomer = async () => {
        setError(null);
    
        try {
          const response = await axios.get('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/retrieve-subscriptions', {
            params: { customerEmail: user.email },
          });
    
          console.log('Customer data:', response.data);
          setCustomerData(response.data);
        } catch (error) {
          setError('Customer retrieval failed');
        }
    };

    useEffect(() => {
        if (user?.email) {
            handleRetrieveCustomer();
        }
    }, [user, loading]);
  
    const logout = () => {
        signOut(auth)
        .catch((error) => {
            console.error(error);
        });
    };

    const handleCancelSubscription = async () => {
        setLoading(true);
        
        try {
            const response = await axios.post('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/cancel-subscription', {
                subscriptionId: customerData?.data[0]?.id,
            });
    
            if (response.status === 200) {
                console.log('Subscription canceled successfully:', response.data);
            } else {
                throw new Error('Failed to cancel subscription');
            }
            setAlert(false)
        } catch (error) {
            const errorMessage = error?.response?.data?.error || error.message;
            console.error('Error canceling subscription:', errorMessage);
        } finally{
            setLoading(false);
        }
    };

    return (
      <div className='conteniner'>
        <header className='header'>
            <Link to={'/'} className='header-logo'>
                <img src={logo} alt='Smart Logo' />
                Smart Surf
            </Link>

            {user ? (
                <div className='profile-block'>

                    {customerData?.data.length > 0 ? 
                        <>
                            <div className='plan-status'>
                                {customerData.data[0].plan.interval} Plan
                            </div>
                        </>
                        :
                        <div className='plan-status'>
                            Free Plan
                        </div>
                    }
                    <p>
                        {user.email}
                    </p>
                    <div className='profile-logo' onClick={() => setUserDropdown(!userDropdown)}>
                        {user.photoURL ?
                            <img src={user.photoURL} alt='Profile' />
                            :
                            <FaRegUser />
                        }
                    </div>
                    {userDropdown &&
                        <div className='profile-dropdown'>
                            {customerData?.data.length > 0 && 
                                <>
                                    {customerData?.data[0].cancel_at_period_end ?
                                        <p className='dropdown-btn sub-text'>
                                            You do not have an active subscription
                                        </p>
                                        :
                                        <button className='dropdown-btn red-text' 
                                            onClick={() => {
                                                if(customerData?.data[0].status === 'trialing') {
                                                    setAlert(true);
                                                    setUserDropdown(!userDropdown)
                                                } else {
                                                    setUserDropdown(!userDropdown)
                                                    handleCancelSubscription();
                                                }
                                            }}
                                        >
                                            Cancel Subscription
                                        </button>
                                    }
                                </>
                            }
                            <button className='dropdown-btn' onClick={logout}>
                                <CiLogout className='rotate' />
                                Logout
                            </button>
                        </div>
                    }
                </div>
            ) : (
                <div className='reg-block'>
                    <button className='login' onClick={() => {setSignIn(true)}}>Sign In</button>
                    <button className='reg' onClick={() => {setSignUp(true)}}>Sign Up</button>
                </div>
            )}
        </header>

        <div>
        <div class="section">
        <h1>Terms of Service</h1>
        <p><strong>Effective Date:</strong> June 20, 2024</p>
        <p>Welcome to the SmartSurf Chrome Extension (“Extension”). These Terms of Service (“Terms”) govern your use of the Extension provided by SP-Lutsk LLC (“we”, “our”, “us”). By using the Extension, you agree to these Terms.</p>
    </div>
    <div class="section">
        <h2>Use of the Extension</h2>
        <p><strong>License:</strong> We grant you a non-exclusive, non-transferable, revocable license to use the Extension for personal, non-commercial use, in accordance with these Terms.</p>
        <p><strong>Restrictions:</strong> You agree not to:</p>
        <ul>
            <li>Modify, copy, or create derivative works of the Extension.</li>
            <li>Use the Extension for any unlawful or harmful purpose.</li>
            <li>Reverse engineer or attempt to extract the source code of the Extension.</li>
        </ul>
    </div>
    <div class="section">
        <h2>User Accounts</h2>
        <p><strong>Account Creation:</strong> To use certain features of the Extension, you may be required to create an account using Firebase Authentication. You must provide accurate and complete information when creating your account.</p>
        <p><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
    </div>
    <div class="section">
        <h2>Privacy</h2>
        <p>Our Privacy Policy describes how we collect, use, and disclose information about you. By using the Extension, you consent to our collection and use of your information as described in the Privacy Policy.</p>
    </div>
    <div class="section">
        <h2>Intellectual Property</h2>
        <p>All intellectual property rights in the Extension, including all software, content, and trademarks, are owned by SP-Lutsk LLC or its licensors. You do not acquire any ownership rights by using the Extension.</p>
    </div>
    <div class="section">
        <h2>Termination</h2>
        <p>We may terminate or suspend your access to the Extension at any time, without prior notice or liability, for any reason, including if you breach these Terms.</p>
    </div>
    <div class="section">
        <h2>Disclaimer of Warranties</h2>
        <p>The Extension is provided “as is” and “as available” without warranties of any kind, either express or implied. We do not warrant that the Extension will be uninterrupted, error-free, or secure.</p>
    </div>
    <div class="section">
        <h2>Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, SP-Lutsk LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul>
            <li>Your use of or inability to use the Extension.</li>
            <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
        </ul>
    </div>
    <div class="section">
        <h2>Changes to These Terms</h2>
        <p>We may update these Terms from time to time. Any changes will be posted on this page with an updated revision date. Your continued use of the Extension after any changes indicates your acceptance of the new Terms.</p>
    </div>
    <div class="section">
        <h2>Governing Law</h2>
        <p>These Terms shall be governed by and construed in accordance with the laws of Ukraine, without regard to its conflict of law principles.</p>
    </div>
    <div class="section">
        <h2>Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <p>
            SP-Lutsk LLC
            Email: <a href="mailto:smart-surf@sp-lutsk.com">smart-surf@sp-lutsk.com</a>
        </p>
    </div>
        </div>

        <div className='footer-block'>
            <div>
                <Link to={'/'} className='header-logo'>
                    <img src={logo} alt='Smart Logo' />
                    Smart Surf
                </Link>
                <p className='grey-text'>
                    ©2024 SmartSurf
                </p>
            </div>

            <div className='footer-right'>
                {/* <div>
                    <h6>Product</h6>
                    <p>Download</p>
                    <p>Pricing</p>
                </div> */}

                <div>
                    <h6>Engage</h6>
                    <Link className='footer-link' to="/privacy-policy">Privacy Policy</Link>
                    <Link className='footer-link' to="/terms-of-service">Terms of Service</Link>
                </div>
            </div>

        </div>
      </div>
    );
}

export default TermsOfService;