import '../App.css';
import logo from '../logo.svg';
import img1 from '../img/Illustration1.png';
import img2 from '../img/Illustration2.png';
import img3 from '../img/Standard.png';
import img4 from '../img/Premium.png';
import { FaCheck, FaRegUser, FaUser } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { auth, googleProvider } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import axios from 'axios';
import { CiLogout } from "react-icons/ci";
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
    const [signUp, setSignUp] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const [alert, setAlert] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [userDropdown, setUserDropdown] = useState(false);

    const user = useUser();
    
    const handleRetrieveCustomer = async () => {
        setError(null);
    
        try {
          const response = await axios.get('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/retrieve-subscriptions', {
            params: { customerEmail: user.email },
          });
    
          console.log('Customer data:', response.data);
          setCustomerData(response.data);
        } catch (error) {
          setError('Customer retrieval failed');
        }
    };

    useEffect(() => {
        if (user?.email) {
            handleRetrieveCustomer();
        }
    }, [user, loading]);
  
    const logout = () => {
        signOut(auth)
        .catch((error) => {
            console.error(error);
        });
    };

    const handleCancelSubscription = async () => {
        setLoading(true);
        
        try {
            const response = await axios.post('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/cancel-subscription', {
                subscriptionId: customerData?.data[0]?.id,
            });
    
            if (response.status === 200) {
                console.log('Subscription canceled successfully:', response.data);
            } else {
                throw new Error('Failed to cancel subscription');
            }
            setAlert(false)
        } catch (error) {
            const errorMessage = error?.response?.data?.error || error.message;
            console.error('Error canceling subscription:', errorMessage);
        } finally{
            setLoading(false);
        }
    };

    return (
      <div className='conteniner'>
        <header className='header'>
            <Link to={'/'} className='header-logo'>
                <img src={logo} alt='Smart Logo' />
                Smart Surf
            </Link>

            {user ? (
                <div className='profile-block'>

                    {customerData?.data.length > 0 ? 
                        <>
                            <div className='plan-status'>
                                {customerData.data[0].plan.interval} Plan
                            </div>
                        </>
                        :
                        <div className='plan-status'>
                            Free Plan
                        </div>
                    }
                    <p>
                        {user.email}
                    </p>
                    <div className='profile-logo' onClick={() => setUserDropdown(!userDropdown)}>
                        {user.photoURL ?
                            <img src={user.photoURL} alt='Profile' />
                            :
                            <FaRegUser />
                        }
                    </div>
                    {userDropdown &&
                        <div className='profile-dropdown'>
                            {customerData?.data.length > 0 && 
                                <>
                                    {customerData?.data[0].cancel_at_period_end ?
                                        <p className='dropdown-btn sub-text'>
                                            You do not have an active subscription
                                        </p>
                                        :
                                        <button className='dropdown-btn red-text' 
                                            onClick={() => {
                                                if(customerData?.data[0].status === 'trialing') {
                                                    setAlert(true);
                                                    setUserDropdown(!userDropdown)
                                                } else {
                                                    setUserDropdown(!userDropdown)
                                                    handleCancelSubscription();
                                                }
                                            }}
                                        >
                                            Cancel Subscription
                                        </button>
                                    }
                                </>
                            }
                            <button className='dropdown-btn' onClick={logout}>
                                <CiLogout className='rotate' />
                                Logout
                            </button>
                        </div>
                    }
                </div>
            ) : (
                <div className='reg-block'>
                    <button className='login' onClick={() => {setSignIn(true)}}>Sign In</button>
                    <button className='reg' onClick={() => {setSignUp(true)}}>Sign Up</button>
                </div>
            )}
        </header>

        <div className=''>
        <div className="section">
          <h1>Privacy Policy</h1>
          <p><strong>Effective Date:</strong> June 20, 2024</p>
          <p>SP-Lutsk LLC (“we”, “our”, “us”) is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our SmartSurf Chrome Extension (“Extension”).</p>
      </div>
        <div className="section">
            <h2>Information We Collect</h2>
            <p>We collect the following types of information from you when you use the Extension:</p>
            <ul>
                <li>
                    <strong>Personal Information</strong>
                    <p>Firebase Authentication: To perform user sign-in, we collect and store your email address and any other information you provide during the sign-in process via Firebase.</p>
                </li>
                <li>
                    <strong>Usage Data</strong>
                    <p>Browsing Data: Information about the websites you visit, including URLs and page content, which is used to block ads and summarize page content.</p>
                    <p>Interaction Data: Information about your interactions with the Extension, such as the features you use and your preferences.</p>
                </li>
            </ul>
        </div>
        <div className="section">
            <h2>How We Use Your Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
                <li>Authentication: To authenticate users and provide access to the Extension.</li>
                <li>Ad Blocking: To block advertisements on web pages you visit.</li>
                <li>Content Summarization: To summarize the content of web pages for easier reading.</li>
                <li>Improving the Extension: To analyze usage patterns and improve the functionality and user experience of the Extension.</li>
                <li>Customer Support: To provide customer support and respond to inquiries.</li>
            </ul>
        </div>
        <div className="section">
            <h2>Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
            <ul>
                <li>
                    <strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our business and providing our services, such as Firebase for authentication.
                </li>
                <li>
                    <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                </li>
            </ul>
        </div>
        <div className="section">
            <h2>Data Security</h2>
            <p>We implement a variety of security measures to maintain the safety of your personal information. All collected data is stored securely and only accessible by authorized personnel.</p>
        </div>
        <div className="section">
            <h2>Your Rights</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
                <li><strong>Correction:</strong> You can request that we correct any inaccuracies in your personal information.</li>
                <li><strong>Deletion:</strong> You can request that we delete your personal information.</li>
            </ul>
            <p>To exercise any of these rights, please contact us at the contact information provided below.</p>
        </div>
        <div className="section">
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
        </div>
        <div className="section">
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <p>
            SP-Lutsk, LLC
            Lutsk, Ukraine
            Email: <a href="mailto:smart-surf@sp-lutsk.com">smart-surf@sp-lutsk.com</a>
        </p>
    </div>
        </div>

        <div className='footer-block'>
            <div>
                <Link to={'/'} className='header-logo'>
                    <img src={logo} alt='Smart Logo' />
                    Smart Surf
                </Link>
                <p className='grey-text'>
                    ©2024 SmartSurf
                </p>
            </div>

            <div className='footer-right'>
                {/* <div>
                    <h6>Product</h6>
                    <p>Download</p>
                    <p>Pricing</p>
                </div> */}

                <div>
                    <h6>Engage</h6>
                    <Link className='footer-link' to="/privacy-policy">Privacy Policy</Link>
                    <Link className='footer-link' to="/terms-of-service">Terms of Service</Link>
                </div>
            </div>

        </div>
      </div>
    );
}

export default PrivacyPolicy;