import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Success from './page/Success';
import Cancel from './page/Cancel';
import PrivacyPolicy from './page/PrivacyPolicy';
import TermsOfService from './page/TermsOfService';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </>
  );
}

export default App;
