import '../App.css';
import logo from '../logo.svg';
import img1 from '../img/Illustration1.png';
import img2 from '../img/Illustration2.png';
import img3 from '../img/Standard.png';
import img4 from '../img/Premium.png';
import { FaCheck, FaRegUser, FaUser } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { auth, googleProvider } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import axios from 'axios';
import { CiLogout } from "react-icons/ci";
import { Link } from 'react-router-dom';

function Success() {
    const [signUp, setSignUp] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const [alert, setAlert] = useState(false);
    const [customerData, setCustomerData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [userDropdown, setUserDropdown] = useState(false);

    const user = useUser();
    
    const handleRetrieveCustomer = async () => {
        setError(null);
    
        try {
          const response = await axios.get('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/retrieve-subscriptions', {
            params: { customerEmail: user.email },
          });
    
          console.log('Customer data:', response.data);
          setCustomerData(response.data);
        } catch (error) {
          setError('Customer retrieval failed');
        }
    };

    useEffect(() => {
        if (user?.email) {
            handleRetrieveCustomer();
        }
    }, [user, loading]);
  
    const logout = () => {
        signOut(auth)
        .catch((error) => {
            console.error(error);
        });
    };

    const handleCancelSubscription = async () => {
        setLoading(true);
        
        try {
            const response = await axios.post('https://us-central1-smart-surf-be4d8.cloudfunctions.net/api/cancel-subscription', {
                subscriptionId: customerData?.data[0]?.id,
            });
    
            if (response.status === 200) {
                console.log('Subscription canceled successfully:', response.data);
            } else {
                throw new Error('Failed to cancel subscription');
            }
            setAlert(false)
        } catch (error) {
            const errorMessage = error?.response?.data?.error || error.message;
            console.error('Error canceling subscription:', errorMessage);
        } finally{
            setLoading(false);
        }
    };

    return (
      <div className='conteniner'>
        <header className='header'>
            <Link to={'/'} className='header-logo'>
                <img src={logo} alt='Smart Logo' />
                Smart Surf
            </Link>

            {user ? (
                <div className='profile-block'>

                    {customerData?.data.length > 0 ? 
                        <>
                            <div className='plan-status'>
                                {customerData.data[0].plan.interval} Plan
                            </div>
                        </>
                        :
                        <div className='plan-status'>
                            Free Plan
                        </div>
                    }
                    <p>
                        {user.email}
                    </p>
                    <div className='profile-logo' onClick={() => setUserDropdown(!userDropdown)}>
                        {user.photoURL ?
                            <img src={user.photoURL} alt='Profile' />
                            :
                            <FaRegUser />
                        }
                    </div>
                    {userDropdown &&
                        <div className='profile-dropdown'>
                            {customerData?.data.length > 0 && 
                                <>
                                    {customerData?.data[0].cancel_at_period_end ?
                                        <p className='dropdown-btn sub-text'>
                                            You do not have an active subscription
                                        </p>
                                        :
                                        <button className='dropdown-btn red-text' 
                                            onClick={() => {
                                                if(customerData?.data[0].status === 'trialing') {
                                                    setAlert(true);
                                                    setUserDropdown(!userDropdown)
                                                } else {
                                                    setUserDropdown(!userDropdown)
                                                    handleCancelSubscription();
                                                }
                                            }}
                                        >
                                            Cancel Subscription
                                        </button>
                                    }
                                </>
                            }
                            <button className='dropdown-btn' onClick={logout}>
                                <CiLogout className='rotate' />
                                Logout
                            </button>
                        </div>
                    }
                </div>
            ) : (
                <div className='reg-block'>
                    <button className='login' onClick={() => {setSignIn(true)}}>Sign In</button>
                    <button className='reg' onClick={() => {setSignUp(true)}}>Sign Up</button>
                </div>
            )}
        </header>

        <div className='success-block'>
            <div className='success-text'>
                <h1>Thank you for subscribing!</h1>
                <p>
                    You now have access to all of our premium features.
                </p>
                <Link to={'/'} className='plan-btn plan-btn-blue'>
                  Go Home
                </Link>
            </div>
        </div>

        <div className='footer-block'>
            <div>
                <Link to={'/'} className='header-logo'>
                    <img src={logo} alt='Smart Logo' />
                    Smart Surf
                </Link>
                <p className='grey-text'>
                    ©2024 SmartSurf
                </p>
            </div>

            <div className='footer-right'>
                {/* <div>
                    <h6>Product</h6>
                    <p>Download</p>
                    <p>Pricing</p>
                </div> */}

                <div>
                    <h6>Engage</h6>
                    <Link className='footer-link' to="/privacy-policy">Privacy Policy</Link>
                    <Link className='footer-link' to="/terms-of-service">Terms of Service</Link>
                </div>
            </div>

        </div>
      </div>
    );
}

export default Success;