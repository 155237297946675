// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBJusnSbzAQhWzQlqlNV84qT3_abzRCI1E",
    authDomain: "smart-surf-be4d8.firebaseapp.com",
    projectId: "smart-surf-be4d8",
    storageBucket: "smart-surf-be4d8.appspot.com",
    messagingSenderId: "801747009282",
    appId: "1:801747009282:web:67eedeee212ab5f48b0c1b",
    measurementId: "G-J5KY2NZ2PE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const functions = getFunctions(app);

export { auth, googleProvider, functions  };
